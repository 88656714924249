.container {
  width: 100%;
  background-color: #f1f1f1;
  justify-content: "flex-end";
  align-items: center;
}
.Text {
  color: white;
}
.newJob {
  width: 95%;
  height: 70px;
  background-color: green;
  align-items: center;
  justify-content: center;
}
