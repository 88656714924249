.container {
  width: 100%;
  background-color: #f1f1f1;
  justify-content: "flex-end";
  align-items: center;
}
.Text {
  color: white;
}
.newJobOQ {
  height: 11vh;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.existingJobBtn2TSOQ {
  width: 100%;
  height: 5vh;
  background-color: green;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
  margin-bottom: 1vh;
}
