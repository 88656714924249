.containerSearch {
  width: 100%;
  background-color: #f1f1f1;
  align-items: center;
  justify-content: flex-end;
  height: 6vh;
  border-radius: 100px;
  margin-bottom: 2vh;
  margin-top: 2vh;
}
.Text {
  color: black;
  justify-content: flex-start;
  border-radius: 20;
  padding: 15px;
}
.Search {
  background-color: #f1f1f1;
  align-items: center;
  justify-content: center;
  border-color: black;
  border-style: solid;
  border-width: 4;
  margin: 2.5%;
  border-radius: 20px;
}
.searchBar__unclicked {
  margin-top: 15px;
  padding: 10;
  padding-left: 1;
  flex-direction: row;
  background-color: #d9dbda;
  border-radius: 15px;
  align-items: center;
}
.searchBar__clicked {
  margin-top: 15px;
  padding: 10;
  flex-direction: row;
  border-radius: 30;
  align-items: center;
  justify-content: space-evenly;
  height: 6vh;
}
.inputSearch {
  padding: 5px;
  padding-left: 10px;
  width: 96%;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  margin-left: 2%;
  margin-right: 2%;
  height: 6vh;
  border-radius: 15px;
  background-color: #d9dbda;
  border-width: 1px;
  border-color: black;
}
