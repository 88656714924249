@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 7vw;
  height: 7vw;
  border: 0.3vw solid #f3f3f3; /* Light grey */
  border-top: 0.3vw solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.spinner-global-container {
  width: 100%;
  height: 100%;
  justify-content: center;
  position: fixed;
  align-items: center;
  display: grid;
  top: 0;
  z-index: 100;
}
.spinner-container {
  width: 10vw;
  height: 10vw;
  border: 0.1vw solid #f3f3f3; /* Light grey */
  border: 0.1vw solid #383636; /* Black */
  justify-content: center;
  align-items: center;
  display: grid;
  border-radius: 2vw;
  background-color: lightgray;
}
