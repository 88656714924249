.globalContainerJSA {
  width: 98%;
  flex-grow: 1;
  padding-bottom: "3%";
  margin: 1%;
}
.rowone {
  flex: 1.2;
}
.header {
  flex: 1;
  margin: 1%;
}

.rowtwo {
  flex: 2;
}
.bjsat1 {
  flex: 1;
  margin: 1%;
}

.rowthree {
  flex: 1.2;
}
.bjsat2 {
  flex: 1;
  margin: 1%;
}

.RowFourJSA {
  flex: 2.4;
  flex-direction: row;
  border-style: solid;
  border-width: 1px;
}
.bjsat3 {
  flex: 1;
  margin: 1%;
}
.rowfive {
  flex: 1.4;
}
.bjsat4 {
  flex: 1;
  margin: 1%;
}

.rowsix {
  flex: 3;
}
.bjsat5 {
  flex: 1;
  margin: 1%;
}
.RowSeven {
  flex: 3;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 2%;
}
.bjsat6 {
  flex: 1;
  margin: 1%;
}
.RowEight {
  flex: 3;
  border-style: solid;
  border-width: 1px;
}
.bjsat7 {
  flex: 1;
  margin: 1%;
}
.RowNineTen {
  display: flex;
  margin-bottom: 2%;
}
.RowNine {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-right: 0px;
}
.bjsat8 {
  flex: 1;
  margin: 1%;
}
.RowTen {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-left: 0px;
}
.bjsat9 {
  flex: 1;
  margin: 1%;
}
.RowEleven {
  flex: 1;
  border-style: solid;
  border-width: 1px;
}
.bjsat10 {
  flex: 1;
  margin: 1%;
}
.subbtn {
  background-color: green;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
}
.locktext {
  color: white;
  margin-left: "45%";
}
.SubBtnJSA {
  cursor: pointer;
  width: 100%;
  flex: 1;
  background-color: #f1f1f1;
  justify-content: center;
  align-content: center;
  display: flex;
  height: 100%;
  border-width: 0px;
  padding: 0%;
  font-size: 2vh;
}
@media screen and (max-width: 768px) {
}

@media print {
  .footerPage {
    page-break-inside: avoid;
  }
  .RowOne {
    page-break-inside: avoid;
  }
  .RowTwo {
    page-break-inside: avoid;
  }
  .RowThree {
    page-break-inside: avoid;
  }
  .RowFive {
    page-break-inside: avoid;
  }
  .RowFour {
    page-break-inside: avoid;
  }
  .RowSeven {
    page-break-inside: avoid;
  }
  .RowSix {
    page-break-inside: avoid;
  }
  .RowEight {
    page-break-inside: avoid;
  }
  .RowNineTen {
    page-break-inside: avoid;
  }
  .RowNine {
    page-break-inside: avoid;
  }
  .RowTen {
    page-break-inside: avoid;
  }
  .RowEleven {
    page-break-inside: avoid;
  }
  .AddBtnJSA11 {
    display: none;
  }
  .AddBtnJSA10 {
    display: none;
  }
  .AddBtnJSA9 {
    display: none;
  }
}
