.body {
  width: 100%;
  height: 100%;
  flex-direction: column;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
}
.Title {
  flex: 1.2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
.RowJSAT1 {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-content: center;
}
.ColumnTitle {
  flex: 2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  margin-bottom: -1;
}
.TitleText1 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.TitleText2 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .RowOne {
    margin-bottom: 10%;
  }
  .RowTwo {
    margin-bottom: 10%;
  }
  .RowThree {
    margin-bottom: 10%;
  }
  .RowFive {
    margin-bottom: 10%;
  }
}
