.body {
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.Title {
  flex: 1.2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
.Row {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-content: center;
}
.ColumnTitle {
  flex: 2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  margin-bottom: -1;
}
.TitleText1 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.TitleText2 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.true {
  width: 100%;
  height: 100%;
  background-color: green;
  justify-content: center;
  align-content: center;
}
.trueText {
  text-align: center;
  color: white;
  font-family: "Inter Tight", sans-serif;
  height: 100%;
  font-size: 1.4vh;
}
.false {
  width: 100%;
  height: 100%;
  background-color: white;
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .BJSAT6 {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 10%;
  }
  .false {
    height: 2vh;
  }
  .true {
    height: 2vh;
  }
}
