.nav-link {
  display: relative;
  color: var(--text-primary);
  text-decoration: none;
  filter: grayscale(100%) opacity(0.9);
  transition: var(--transition-speed);
  width: 0%;
  color: white;
  margin: auto;
  transition: transform 400ms ease;
  justify-content: center;
}
.navIconLeft {
  padding-right: 2%;
}
.navIconRight {
  padding-left: 2%;
}
.navIconMain {
  padding-left: 1%;
  transition: all 800ms ease;
  color: white;
  font-size: 3vh;
}

.ContainerNavbar:hover .navIconMain {
  padding-left: 0%;
  transition: all 800ms ease;
  padding-bottom: 1%;
  transform: rotate(90deg);
}
.ContainerNavbar {
  /*scroll-snap-stop: always;
  scroll-snap-align: start;*/
  height: 4vh;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  z-index: 11;
  transition: height 500ms ease;
  align-items: center;
  background-color: black;
  top: 0;
  position: relative;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 2px;
  border-color: #29c601;
}
.ContainerNavbar:hover {
  height: 8vh;
  border-bottom-width: 3px;
}
.ContainerNavbar:hover .jobsCont {
  height: 70vh;
}
.ContainerNavbar:hover .GC {
  height: 78vh;
}
.ContainerNavbar:hover .BtnText {
  display: inline;
}
.ContainerNavbar:hover .BtnMain {
  display: list-item;
}
.ContainerNavbar:hover .BtnSecond {
  display: list-item;
}
@media screen and (max-width: 768px) {
  .ContainerNavbar {
    height: 4vh;
  }
  .ContainerNavbar:hover {
    height: 20vh;
  }
  .ContainerNavbar:hover .GC {
    height: 78vh;
  }
  .ContainerNavbar:hover .TimesheetGC {
    height: 78vh;
  }
}
.NavBtnFlex {
  padding-inline-start: 0;
  margin-block-start: 0em;
  margin-block-end: 0em;
  list-style: none;
  justify-content: center; /* align horizontal */
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  height: 100%;
}

.Btn {
  text-align: left;
  width: 100%;
  flex: 10;
  align-items: center;
  justify-content: left;
  text-align: center;
  margin: auto;
  text-decoration: none;
  color: white;
  margin-left: 2.5%;
  font-family: "Inter Tight", sans-serif;
  font-family: "Inter Tight", sans-serif;
  font-size: 2.2vh;
  display: flex;
}
.BtnText {
  display: none;
}
.BtnMain {
  text-align: left;
  width: 100%;
  flex: 20;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-decoration: none;
  color: white;
  font-family: "Inter Tight", sans-serif;
  font-family: "Inter Tight", sans-serif;
  font-size: 2.2vh;
}
.BtnSecond {
  text-align: right;
  width: 100%;
  flex: 5;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-decoration: none;
  color: white;
  font-family: "Inter Tight", sans-serif;
  font-family: "Inter Tight", sans-serif;
  font-size: 2.2vh;
}
.BtnLogo {
  text-align: left;
  width: 100%;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-decoration: none;
  color: white;
  margin-left: 2.5%;
}
.LogoNav {
  border-radius: 20%;
  height: 80%;
  margin: 10%;
}
.Btn:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .LogoNav {
    height: 30%;
  }
  .BtnSecond {
    flex: 20;
  }
  .Btn {
    justify-content: center;
  }
}
