.containerTSD {
  width: 95%;
  margin-left: auto;
  /* height: 100%; */
  background-color: #f1f1f1;
  justify-content: "flex-end";
  flex: 0 1 10%;
  align-items: center;
  /* height: 10vh; */
  aspect-ratio: 1/1;
  margin-top: 1vh;
  margin-bottom: 1vh;
  display: flex;
  border-radius: 10%;
}
.existingJobTSD {
  width: 100%;
  height: 100%;
  border-radius: 10%;
  align-items: center;
  justify-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.fileTypeBtnTSD {
  width: 100%;
  height: 100%;
  background-color: green;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  color: white;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}
.btnTSD {
  height: 100px;
  width: 100px;
}
.existingJobBtnTSD {
  word-break: break-all;
  width: 100%;
  height: 100%;
  border-radius: 5%;
  background-color: #e4e4e4;
  text-decoration: none;
  color: #272727;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  border-color: #c6c3c3;
  border-width: 1px;
  border-style: solid;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}
.existingJobBtnTSD:hover {
  background-color: #272727;
  color: white;
}
.TextTSD {
  color: white;
}
@media screen and (max-width: 768px) {
  .GCTSD {
    display: flex;
    flex: 2;
    padding: 3%;
  }
  .containerTSD {
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    /* height: 100%; */
    background-color: #f1f1f1;
    justify-content: "flex-end";
    /* flex: 0 1 10%; */
    align-items: center;
    height: 10vh;
    aspect-ratio: 1/1;
    flex: 1;
    margin-top: 0vh;
    margin-bottom: 0vh;
    display: flex;
  }
}
