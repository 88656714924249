.container {
  width: 100%;
  background-color: #f1f1f1;
  justify-content: "flex-end";
  align-items: center;
  margin-left: 2.5%;
}
.text {
  color: white;
}
.newjob {
  width: 95%;
  height: 100%;
  background-color: green;
  align-items: center;
  justify-content: center;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}
.NewJobBtn {
  width: 100%;
  height: 100%;
  background-color: green;
  color: white;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}
.NewJobBtn:hover {
  cursor: pointer;
}
