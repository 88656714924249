.body {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  margin-bottom: 0%;
  background-color: #d7d7d7;
}
.TextTitle {
  text-align: "right";
  padding-right: 5px;
  padding-left: 5px;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.TextInput {
  border-style: solid;
  width: 100%;
  height: 100%;
  flex: 1;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  position: relative;
  border-width: 0px;
  padding: 0%;
  padding-left: 1%;
}
.Title {
  flex: 1.2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
.Row {
  flex: 4 1;
  padding: 1px;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-content: center;
  padding: 0%;
}
.RowBool {
  flex: 1 1;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-content: center;
  padding: 0%;
  padding-left: 0%;
  padding-top: 0%;
  padding-bottom: 0%;
}
.Row2 {
  flex: 2;
  padding: 1px;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-content: center;
}
.Row3 {
  flex: 8;
  padding: 1px;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-content: center;
}
.TitleText1 {
  justify-content: center;
  align-content: center;
  text-align: center;
}

.Column2 {
  flex: 1;
  flex-direction: row;
  display: flex;
}
@media screen and (max-width: 768px) {
  .Column2 {
    flex: 1;
    flex-direction: column;
    display: flex;
  }
}
