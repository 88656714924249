.existingJobJob {
  width: 100%;
  height: 10vh;
  background-color: #272727;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.2%;
  display: flex;
  font-size: 3vh;
}
.fileTypeBtn {
  width: 100%;
  height: 5%;
  background-color: green;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  color: white;
}
.btn {
  height: 100px;
  width: 100px;
}
.existingJobBtn {
  width: 100%;
  height: 100%;
  background-color: #272727;
  color: white;
  text-decoration: none;
  text-align: center;
  font-family: "Inter Tight", sans-serif;
  font-size: 3vh;
}
.existingJobBtn:hover {
  cursor: pointer;
}
.Text {
  color: white;
}
.Scroll {
  max-height: 100%;
}
.allTimesheetContent {
  flex: 10;
  display: flex;
  flex-direction: row-reverse;
}
.leftCont {
  margin-top: -0.2%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-left: 0.5%;
  padding-top: 0.5%;
  padding-right: 0.5%;
  background-color: #272727;
}

.leftContCont {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 25%;
}
.GC {
  position: relative;
  min-height: 100vh;
  width: 100%;
  margin-bottom: 1%;
}
.allJobFiles {
  height: 100%;
}
.snap {
}
@media screen and (max-width: 768px) {
  .existingJob {
    margin-bottom: 0.2%;
  }
  .GC {
    min-height: 86vh;
  }

  .allTimesheetContent {
    flex-direction: column-reverse;
  }
  .allTimesheetContent {
    flex-direction: column-reverse;
  }
  .allTimesheetContent {
    flex-direction: column-reverse;
  }
  .allTimesheetContent {
    flex-direction: column-reverse;
  }
  .leftCont {
    width: 100%;
    position: fixed;
    z-index: 100000;
    bottom: 0%;
    margin-left: 0%;
    margin-top: 0%;
    background-color: #272727;
  }
  .leftContSticky {
    display: flex;
  }
  .containerBar {
    width: 100%;
    margin-left: 0%;
    background-color: #272727;
    align-self: center;
  }
}
