.globalcontainer {
  width: 90%;
  flex: 1;
}
.RowOne {
  flex: 1.2;
  border-style: solid;
  border-width: 0px;
}
.header {
  flex: 1;
  width: 98%;
  margin-top: 1%;
}

.RowTwo {
  flex: 2;
  border-style: solid;
  border-width: 0px;
}
.BT1 {
  flex: 1;
  margin-top: 1%;
}

.RowThree {
  flex: 1.2;
  margin-bottom: 2%;
  border-style: solid;
  border-width: 0px;
}
.BT2 {
  margin-top: 1%;
  flex: 1;
  border-width: 0px;
}

.RowFour {
  flex: 2.4;
  flex-direction: row;
  display: flex;
  border-width: 0px;
}
.BT3 {
  margin-top: 1%;
  flex: 1;
  border-style: solid;
  border-width: 1px;
  margin-left: 0%;
}
.BT4 {
  margin-top: 1%;
  flex: 1;
  border-style: solid;
  border-width: 1px;
}
.BT5 {
  margin-top: 1%;
  flex: 1;
  margin-right: 0%;
  border-style: solid;
  border-width: 1px;
}

.RowFive {
  flex: 1.4;
  border-style: solid;
  border-width: 0px;
}
.BT6 {
  flex: 1;
  margin-top: 1%;
}

.RowSix {
  flex: 3;
  border-style: solid;
  border-width: 0px;
}
.BT7 {
  flex: 1;
  margin-top: 1%;
}
@media screen and (max-width: 768px) {
  .RowOne {
    margin-bottom: 10%;
  }
  .RowTwo {
    margin-bottom: 10%;
  }
  .RowThree {
    margin-bottom: 10%;
  }
  .RowFour {
    flex: 2.4;
    flex-direction: row;
    display: inline;
  }
  .RowFive {
    margin-top: 10%;
    margin-bottom: 10%;
  }
}

@media print and (max-width: 768px) {
  .RowOne {
    font-size: 3vh;
  }
  .RowTwo {
  }
  .RowThree {
  }
  .RowFour {
    flex: 2.4;
    flex-direction: column;
    display: inline;
  }
  .RowFive {
  }
}
@media print {
  .footerPage {
    page-break-inside: avoid;
  }
  .RowOne {
    page-break-inside: avoid;
  }
  .RowTwo {
    page-break-inside: avoid;
  }
  .RowThree {
    page-break-inside: avoid;
  }
  .RowFive {
    page-break-inside: avoid;
  }
  .RowFour {
    page-break-inside: avoid;
  }
  .RowSeven {
    page-break-inside: avoid;
  }
  .RowSix {
    page-break-inside: avoid;
  }
  .RowEight {
    page-break-inside: avoid;
  }
  .RowNine {
    page-break-inside: avoid;
  }
  .RowTen {
    page-break-inside: avoid;
  }
  .RowEleven {
    page-break-inside: avoid;
  }
  .BT3 {
    page-break-inside: avoid;
  }
  .BT4 {
    page-break-inside: avoid;
  }
  .BT5 {
    page-break-inside: avoid;
  }
}
