html {
  background-color: #f1f1f1;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
}
.App-link {
  color: #61dafb;
}
body {
  overflow-x: hidden;
}
.Policy {
  margin: 5%;
  margin-top: 1%;
}
button {
  border-width: 0;
}
input {
  width: 100%;
}
