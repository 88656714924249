.ContainerFooter {
  /*scroll-snap-align: end;
  scroll-snap-stop: always;*/
  height: 30vh;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 35%,
    rgba(42, 42, 42, 1) 100%
  );
  position: relative;
  border-width: 0px;
  border-bottom: 1px;
  border-style: solid;
}
.FootBtnFlex {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-left: 0%;
}
.FootList {
  display: flex;
  height: 90%;
  width: 98%;
  padding: 1%;
}
.FootBtnMain {
}
.FootBtnSecond {
  flex: 1;
  display: flex;
  align-content: flex-end;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  justify-content: center;
}
.FootBtnText {
  color: white;
  font-family: "Inter Tight", sans-serif;
  font-size: 12px;
  margin-left: 0.5vw;
}
.FootBtnText-Copy {
  color: white;
  font-family: "Inter Tight", sans-serif;
  font-size: 12px;
  margin-left: 0.5vw;
}
.FootIcon {
  font-size: 20px;
}
.FootBtn {
  color: white;
  text-decoration: none;
  bottom: 0px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}
.FootLogo {
  height: 30vh;
  border-color: rgb(67, 67, 67);
  border-style: solid;
  border-width: 0px;
  border-right-width: 1px;
}
.LogoFooter {
  padding: 2vh;
  height: 25vh;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .LogoFooter {
    padding: 3vh;
    height: 8vh;
  }
  .FootBtnSecond {
    flex-direction: column-reverse;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .FootBtnText {
    font-size: 16px;
  }
  .FootBtnText-Copy {
    font-size: 8px;
  }
  .FootList {
    flex-direction: column-reverse;
  }
}
