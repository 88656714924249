.bodyF2 {
  height: 100%;
  flex-direction: column;
  display: flex;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  border-width: 1px;
  border-top-width: 1px;
  border-style: solid;
  border-bottom-width: 0px;
}
.SubBtnFR2 {
  cursor: pointer;
  flex: 1;
  width: 100%;
  padding: 0%;
  height: 100%;
}
.checkmark {
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.TitleF2 {
  flex: 1.2;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.RowsF2 {
  flex: 3;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  display: flex;
  flex-direction: column;
}
.RowF2 {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  border-left-width: 0px;
}
.RowF2v2 {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  border-right-width: 0px;
}
.RowTitleF2 {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  padding-left: 5px;
}
.ColumnF2 {
  flex: 1;
  flex-direction: column;
  display: flex;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.ColumnF2-2 {
  flex: 4;
  flex-direction: column;
  display: flex;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.ColumnTitleF2 {
  flex: 0.3;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  text-align: center;
  padding: 1%;
}
.TitleRowF2 {
  flex: 1;
  border-style: solid;
  border-width: 0px;
  border-top-width: 1px;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}

.TitleRightRowF2 {
  flex: 1;
  border-style: solid;
  border-width: 0;
  border-top-width: 0px;
  border-right-width: 1px;
  border-left-width: 1px;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}

.TitleRight2RowF2 {
  flex: 1;
  border-style: solid;
  border-width: 0px;
  border-top-width: 1px;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.TitleLeftRowF2 {
  flex: 1;
  border-style: solid;
  border-width: 0px;
  border-top-width: 1px;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.TitleText1F2 {
  justify-content: center;
  align-content: center;
  text-align: center;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.TitleText2F2 {
  justify-content: center;
  align-content: center;
  text-align: right;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  margin-bottom: 0.85vh;
}
.TitleText4F2 {
  justify-content: center;
  align-content: center;
  text-align: right;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.textInputTestF2 {
  width: 100%;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  padding: 0%;
  border-width: 0px;
  padding-left: 5px;
  height: 3vh;
}
.trueTextFR {
  text-align: center;
  color: white;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  height: 100%;
}
@media print and (max-width: 768px) {
}
@media screen and (max-width: 768px) {
  input {
    height: auto;
  }
  .textInputTestF2 {
    height: auto;
  }
  .RowTitleF2 {
    font-size: 1vh;
    height: 3vh;
  }
  .TitleText2F2 {
    font-size: 1vh;
    padding-left: 3px;
    padding-right: 3px;
  }
  .TitleText4F2 {
    font-size: 1vh;
    height: 3vh;
    justify-content: center;
    align-items: center;
  }
  .TitleText3F2 {
    font-size: 1vh;
    height: 3vh;
  }
  .trueTextFR {
  }
}
