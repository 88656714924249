.bodyF3 {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.TitleF3 {
  flex: 1.2;
  height: 100%;
}
.TitleRowF3 {
  flex: 2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-content: center;
}
.RowF3 {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-content: center;
}
.ColumnF3 {
  flex: 1;
  flex-direction: row;
  display: flex;
}
.ColumnTitleF3 {
  flex: 2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  margin-bottom: -1;
  padding: 1%;
}
.TitleText1F3 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.TitleText2F3 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.textInputTestF3 {
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  width: 100%;
  padding: 0%;
  border-width: 0px;
  padding-left: 5px;
}
