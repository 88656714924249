.bodyJSAT9 {
  width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex;
}
.body2JSAT9 {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
}
.Title {
  flex: 1.2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
.RowJSAT9 {
  flex: 1 1 0;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-content: center;
}
.ColumnJSAT9 {
  margin-top: 2%;
  flex: 1;
  flex-direction: column;
  display: flex;
  border-width: 0px;
  border-style: solid;
  border-bottom-width: 1px;
}
.ColumnTitle {
  flex: 2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  margin-bottom: -1;
}
.TitleText1 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.TitleText2 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.prevJSAT9 {
  max-width: 100% !important;
  max-height: 100% !important;
  height: auto;
  width: auto;
}
.AddBtnJSAT9 {
  flex: 1;
  justify-content: "center";
  align-content: "center";
  background-color: green;
  height: 100%;
}
.SubBtnJSAT9 {
  flex: 1;
  justify-content: "center";
  align-content: "center";
  height: 100%;
}
.SubBtnJSAT9:hover {
  cursor: pointer;
}
.AddBtnJSAT9:hover {
  cursor: pointer;
}
.test101 {
  height: 3vh;
  width: 100%;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
}
.textInputTestSig {
  width: 100%;
  height: 100%;
  color: black;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  margin: 0%;
  padding: 0%;
  border-style: none;
}
.textInputTestJSAT9 {
  width: 100%;
  height: 100%;
  color: black;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  margin: 0%;
  padding: 0%;
  border-style: none;
}
