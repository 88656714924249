.body {
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.Title {
  flex: 1.2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
.BJSAT3 {
  width: 100%;
}
.Row {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-content: center;
}
.ColumnTitle {
  flex: 2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  margin-bottom: -1;
}
.TitleText1 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.TitleText2 {
  justify-content: center;
  align-content: center;
  text-align: center;
}

.trueText {
  text-align: center;
  font-family: "Inter Tight", sans-serif;
  color: white;
}
.false {
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
}
@media screen and (max-width: 768px) {
  .BJSAT3 {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 10%;
  }
}
