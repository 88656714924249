.containerBar {
  width: 100%;
  background-color: #f1f1f1;
  justify-content: "flex-end";
  align-items: center;
}
.Text {
  color: white;
}
.newJob {
  width: 100%;
  height: 5vh;
  background-color: green;
  align-items: center;
  justify-content: center;
}
.existingJobBtnDup {
  color: white;
  width: 100%;
  height: 5vh;
  background-color: green;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}
.existingJobBtnDup:hover {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .containerBar {
    flex: 1;
  }
}
