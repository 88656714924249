.container {
  width: 94.7%;
  background-color: #f1f1f1;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
}
.existingJob {
  width: 100%;
  height: 10vh;
  background-color: #272727;
  align-items: center;
  justify-content: center;
  margin-bottom: 1px;
  font-size: 3vh;
}
.fileTypeBtn {
  width: 100%;
  height: 70px;
  background-color: green;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  color: white;
}
.btn {
  height: 100px;
  width: 100px;
}
.existingJobBtn {
  width: 100%;
  height: 70px;
  background-color: #f1f1f1;
}
.Text {
  color: white;
}
