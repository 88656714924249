.Line {
  flex: 1;
  height: 100%;
  border-style: solid;
  border-width: 2px;
  width: 100%;
  flex-direction: row;
  border-color: #d4d4d4;
  display: flex;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.body2 {
  flex: 1;
  height: 400;
  border-style: solid;
  border-width: 2px;
  width: 100%;
  flex-direction: row;
  border-color: #d4d4d4;
  display: flex;
}
.textInputTestBody {
  height: 100%;
  width: 100%;
  padding-left: 5px;
  margin: 0%;
  border-width: 0ch;
  box-sizing: border-box;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}

.tempdiv {
  width: 100%;
  height: 100%;
  margin: 0%;
  border-width: 0ch;
}
.bodyScroll {
  width: 100%;
  height: 90%;
}
.body {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-color: #d4d4d4;
}
.Title {
  flex: 1.2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
.Row {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
.ColumnTS {
  flex: 1;
  flex-direction: column;
  width: 100%;
  border-color: #d4d4d4;
  height: 100%;
}
.ColumnTitle {
  flex: 2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  margin-bottom: -1;
}
.TitleText1 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.prev {
  width: 100%;
  height: 100%;
  flex: 1;
}
.SubBtn {
  height: 100%;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-content: center;
  background-color: green;
  color: white;
  padding: 0px;
  border: 0px;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.LockText {
  width: 100%;
  color: white;
  align-content: center;
  text-align: center;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
}
.TitleText2 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.bGridSmall {
  height: 100%;
  flex: 2;
  background-color: #f1f1f1;
  border-color: #d4d4d4;
  border-width: 1px;
  border-style: solid;
}
.bGridMediumBody {
  height: 100%;
  flex: 4;
  background-color: #f1f1f1;
  border-color: #d4d4d4;
  border-width: 1px;
  border-style: solid;
}
.bGridLarge {
  height: 100%;
  flex: 8;
  background-color: #f1f1f1;
  border-color: #d4d4d4;
  border-width: 1px;
  border-style: solid;
}
.trueTS {
  width: 100%;
  height: 100%;
  background-color: green;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
}
.trueTextTS {
  text-align: center;
  color: white;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
  height: 100%;
}
.falseTS {
  width: 100%;
  height: 100%;
  background-color: white;
  justify-content: center;
  align-content: center;
}
.TrueBtn {
  height: 100%;
  width: 100%;
  flex: 1 1;
  justify-content: center;
  align-content: center;
  background-color: green;
  color: white;
  padding: 0px;
  border: 0px;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .Line {
    flex-direction: column;
    flex: 1.2;
    margin-bottom: 5%;
    border-width: 0px;
  }
  .falseTS {
    height: 3vh;
  }
}
@media print {
  .SubBtn {
    display: none;
  }
  .Signature {
    display: none;
  }
  .AddBtnJSA8 {
    display: none;
  }
}
