.GCSig {
  height: 100%;
  width: 100%;
  display: flex;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10000;
  background-color: white;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sigBtns {
  width: 100%;
}
.predivSig {
  margin-top: 1%;
  height: 60%;
  width: 80%;
  border-style: solid;
  border-width: 4px;
  margin-left: 10%;
}
.sigCanvas {
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 4px;
}
.sigCont {
  width: 80vh;
  height: 80vh;
}
.sigCanvas:hover {
  cursor: crosshair;
}
.toggleOverlaySig {
  color: white;
  background-color: green;
  margin: 1%;
  height: 5vh;
  width: 20%;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  margin-left: 39.5%;
}
.toggleOverlaySig:hover {
  cursor: pointer;
}
.buttonsSig {
  color: white;
  background-color: green;
  margin: 1%;
  margin-bottom: 0%;
  width: 48%;
  height: 5vh;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.buttonsSig:hover {
  cursor: pointer;
}
.prevSig {
  height: 5vh;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .sigCont {
    width: 60vw;
    height: 60vw;
  }
  .GCSig {
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
    z-index: 10000;
    background-color: white;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    /* margin-bottom: 10%; */
    align-items: center;
  }
}
