.body {
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.Title {
  flex: 1.2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
.Row {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-content: center;
}
.AddBtnJSA11 {
  cursor: pointer;
  width: 100%;
  background-color: green;
  margin-top: 0%;
  padding-bottom: 0.5%;
}
.AddBtnJSA10 {
  cursor: pointer;
  width: 99%;
  background-color: green;
  margin-top: 0%;
  padding-bottom: 0.5%;
  margin-left: 1%;
}

.AddBtnJSA9 {
  cursor: pointer;
  width: 99%;
  background-color: green;
  margin-top: 0%;
  padding-bottom: 0.5%;
  margin-right: 1%;
}
.bodyJSA11 {
  width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
  padding-top: 0.5%;
  padding-bottom: 0%;
  flex: 6 1;
  background-color: #f1f1f1;
  margin-bottom: 0px;
  border-color: #d4d4d4;
  padding-top: 0%;
}
.ColumnJSA11 {
  flex: 1;
  flex-direction: column;
  display: flex;
}
.Column {
  flex: 1;
  flex-direction: row;
  display: flex;
}
.ColumnTitle {
  flex: 2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  margin-bottom: -1;
}
.TitleText1 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.TitleText2 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.Column2 {
  flex: 1;
}
.ColumnTitle {
  flex: 0.3;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
@media screen and (max-width: 768px) {
  .Column {
    flex-direction: column;
  }
}
