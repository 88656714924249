.bodyJSA8 {
  width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex;
}
.body2JSA8 {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
}
.Title {
  flex: 1.2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
.RowJSA8 {
  flex: 1 1 0;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-content: center;
}
.ColumnJSA8 {
  flex: 1;
  flex-direction: column;
  display: flex;
}
.ColumnTitle {
  flex: 2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  margin-bottom: -1;
}
.TitleText1 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.TitleText2 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.prevJSA8 {
  max-width: 100% !important;
  max-height: 100% !important;
  height: auto;
  width: auto;
}
.AddBtnJSA8 {
  flex: 1;
  justify-content: "center";
  align-content: "center";
  background-color: green;
  height: 100%;
}
.SubBtnJSA8 {
  flex: 1;
  justify-content: "center";
  align-content: "center";
  height: 100%;
}
.SubBtnJSA8:hover {
  cursor: pointer;
}
.AddBtnJSA8:hover {
  cursor: pointer;
}
.test101 {
  height: 5vh;
  width: 100%;
}
.textInputTestSig {
  width: 100%;
  height: 100%;
  color: black;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  margin: 0%;
  padding: 0%;
  border-style: none;
}
