.footerPage {
  flex-direction: row;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.footerPageSig {
  flex: 2;
  flex-direction: row;
  font-size: 2vh;
  height: 30vh;
}
.SubBtn {
  height: 10vh;
  width: 100%;
  flex: 1;
  background-color: green;
  justify-content: center;
  align-content: center;
  color: #fff;
  text-align: center;
  font-size: 2vh;
}
.SubBtn:hover {
  cursor: pointer;
}
.loginText {
  color: #fff;
  text-align: center;
  font-size: 2vh;
}
.prevFF {
  align-content: center;
  background-color: #fff;
  border: 1px solid #000;
  padding: 1%;
  flex: 1 1;
  justify-content: center;
  /* max-height: 19vh; */
  object-fit: contain;
  /* max-width: 25vw; */
  width: 18vh;
  height: 18vh;
}
.prevESText {
  flex: 1;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
  margin-right: 1%;
  text-align: end;
}
.prevFRImg {
  margin-left: 5%;
  margin-right: 5%;
  align-items: center;
  display: flex;
  align-content: center;
  justify-content: flex-end;
}
.prevES {
  flex: 6;
}
@media screen and (max-width: 768px) {
  .prevFF {
    width: 30vw;
    height: 30vw;
  }
  .prevFRImg {
    flex-direction: column;
  }
}
