body {
  font-family: -apple-system;
}
.all {
  height: 100%;
}
.Gloablcontainer {
  background-color: #f1f1f1;
  align-items: center;
  width: 100%;
  position: relative;
}
.blank {
  width: 80%;
  height: 80%;
  position: fixed;
}
@media screen and (max-width: 768px) {
  .Gloablcontainer {
    margin-top: 0%;
  }
}
