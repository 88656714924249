.Cont {
  flex: 5.5;
  display: flex;
  flex-direction: column;
}
.globalFiles {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
}
.GCTS {
  align-items: center;
  margin: 1%;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  aspect-ratio: 1/1;
  flex: 0 1 18%;
  display: flex;
  border-radius: 10%;
}
.containerTS {
  border-radius: 10%;
  /* height: 100%; */
  width: 100%;
  aspect-ratio: 1/1;
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
}
.existingJobTS {
  height: 100%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 20;
}
.existingJob2TS {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  display: flex;
  margin-left: -4vh;
}

.DelTS {
  flex: 1;
}
.fileTypeBtnTS {
  width: 100%;
  height: 100%;
  background-color: green;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  color: white;
}

.existingJobBtn2TS {
  width: 100%;
  height: 5vh;
  background-color: green;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
}
.existingJobBtn3TS {
  background-color: rgb(106, 106, 106);
  color: white;
  height: 4vh;
  width: 4vh;
  border-radius: 50%;
  border: 0;
  align-items: center;
  justify-content: center;
}
.btnTS {
  height: 100px;
  width: 100px;
}
.existingJobBtnTS {
  word-break: break-all;
  width: 100%;
  height: 100%;
  border-radius: 5%;
  background-color: #e4e4e4;
  text-decoration: none;
  color: #272727;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  border-color: #c6c3c3;
  border-width: 1px;
  border-style: solid;
}
.existingJobBtnTS:hover {
  color: white;
  background-color: #272727;
}
.existingJobBtnTS:hover {
  cursor: pointer;
}
.existingJobBtn2TS:hover {
  cursor: pointer;
}
.existingJobBtn3TS:hover {
  cursor: pointer;
}
.textTS {
  color: white;
}
.EditTS {
  flex-direction: row;
  height: 5vh;
  width: 100%;
  background-color: green;
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
  margin-bottom: 1vh;
  align-self: center;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  flex: 1;
}
@media screen and (max-width: 768px) {
  .GCTS {
    align-items: center;
    margin: 1.66%;
    flex: 0 1 30%;
    border-radius: 10%;
  }
  .Cont {
    flex: 3.3 1;
  }
}
@media screen and (min-width: 1200px) {
  .GCTS {
    align-items: center;
    margin: 0.5%;
    flex: 0 1 10%;
    border-radius: 10%;
  }
  .Cont {
    flex: 10 1;
  }
}
