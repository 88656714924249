.OQClickerUp {
  width: 10vw;
  background-color: green;
  justify-content: center;
  align-content: center;
  height: 4vh;
  font-family: "Inter Tight", sans-serif;
  font-size: 2.5vh;
  border-radius: 10px;
}
.OQClickerDown {
  width: 10vw;
  background-color: green;
  justify-content: center;
  align-content: center;
  height: 4vh;
  font-family: "Inter Tight", sans-serif;
  font-size: 2.5vh;
  border-radius: 10px;
}
.OQClickerCount {
  width: 7vw;
  justify-content: center;
  align-content: center;
  text-align: center;
  font-family: "Inter Tight", sans-serif;
  font-size: 2.5vh;
  padding: 5%;
}
.OQClicker {
  position: fixed;
  bottom: 10%;
  left: 5%;
}
.OQDocFlex {
  width: 100%;
  align-content: center;
  text-align: center;
  display: flex;
}
.OQDoc {
  flex: 1;
}
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 35% !important;
  height: 35% !important;
}
.OQClickerDownload {
  position: fixed;
  width: 100%;
  align-content: center;
  text-align: center;
  background-color: green;
  text-decoration: none;
  height: 4vh;
  bottom: 0;
}
.OQClickerDownloadText {
  width: 100%;
  text-decoration: none;
  color: white;
}
@media screen and (max-width: 768px) {
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
  }
}
