textarea {
  height: 100%;
  height: 20vh;
  width: -webkit-fill-available;
}
.bodyF7 {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.TitleF7 {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
.RowF7 {
  flex: 6;
}
.ColumnF7 {
  flex: 1;
  display: flex;
}
.ColumnTitleF7 {
  flex: 0.05;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  padding: 1%;
}
.textInputTestF7 {
  width: 100%;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  padding: 0%;
}
.TitleText1F6 {
  width: 100%;
  height: 3vh;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  padding-left: 2%;
  border-width: 0px;
  padding-left: 5px;
}
