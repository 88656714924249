.bodyF6 {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  border-bottom-width: 1px;
}
.subBodyF6 {
  width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  border-bottom-width: 1px;
}
.TitleF6 {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
.RowF6 {
  flex: 6;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
.ColumnF6 {
  flex: 1;
  display: flex;
}
.ColumnTitleF6 {
  flex: 0.05;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  padding: 0.5%;
}
.ColumnSubTitleF6 {
  text-align: center;
  font-size: 1.3vh;
}
.textInputTestF6 {
  width: 100%;
  height: 3vh;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  padding: 0%;
  border-width: 0px;
  padding-left: 5px;
}
