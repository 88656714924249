.bodyF1 {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
}
.TitleF1 {
  flex: 3;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  text-align: center;
}
.RowF1 {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
.ColumnF1 {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.Column2F1 {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.Column1F6 {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.ColumnTitleF1 {
  flex: 0.05;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  text-align: center;
  padding: 1%;
}

.TitleText1F1 {
  justify-content: center;
  align-content: center;
  text-align: center;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.textInputTestF1 {
  width: 100%;
  height: 3vh;
  padding: 0%;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  border-width: 0px;
  padding-left: 5px;
}
@media screen and (max-width: 768px) {
  .TitleF1 {
    flex: 10;
  }
  .RowF1 {
    flex: 1;
  }
}
