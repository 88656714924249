.containerL {
  flex: 1;
  align-items: center;
  margin: 5%;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
  min-height: 84vh;
}
.Logo {
  margin-top: 5%;
  margin-left: 37.5%;
  width: 25%;
  height: 20%;
  border-radius: 20%;
}
.inputcontainer {
  width: 80%;
}
.inputL {
  padding-left: 0.5%;
  padding-right: 1%;
  padding-bottom: 1%;
  padding-top: 1%;
  margin-top: 1%;
  margin-bottom: 1%;
  width: 60%;
  max-width: 350px;
  /* margin-left: 20%; */
  font-family: "Inter Tight", sans-serif;
  /* font-size: 1.5vh; */
  border-color: #a1a1a1;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
}
.buttonContainerL {
  width: 60%;
  justify-content: center;
  align-items: center;
  margin-top: 40;
}
.inputContainerMain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.buttonL {
  background-color: green;
  width: 40%;
  border-radius: 10px;
  align-items: center;
  height: 6vh;
  margin-left: 30%;
  cursor: pointer;
}
.buttonOutline {
  background-color: #f1f1f1;
  margin-top: 5;
  border-color: green;
  border-width: 2px;
}
.buttonTextL {
  color: white;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
}
.buttonOutlineText {
  color: black;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
}
@media screen and (max-width: 768px) {
  .Logo {
    margin-top: 0%;
    margin-bottom: 10%;
    margin-left: 12.5%;
    width: 75%;
    height: 75%;
    border-radius: 20%;
  }
  .buttonL {
    margin-top: 3%;
    background-color: green;
    width: 60%;
    margin-left: 20%;
    cursor: pointer;
  }
  .containerL {
    flex: 1;
    align-items: center;
    margin: 5%;
    margin-top: 40%;
    font-family: "Raleway", sans-serif;
    font-size: 1.5vh;
  }
}
