.body {
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.Title {
  flex: 1.2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
}
.Row {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  justify-content: center;
  align-content: center;
}
.ColumnTitle {
  flex: 2;
  border-style: solid;
  border-width: 1px;
  border-style: solid;
  margin-bottom: -1;
}
.TitleText1 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
.TitleText2 {
  justify-content: center;
  align-content: center;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .BJSAT2 {
    margin-bottom: 10%;
  }
}
