.gc {
  width: 95%;
  background-color: #f1f1f1;
  align-items: center;
  margin-left: 2.5%;
}
.container {
  width: 100%;
  flex-direction: row;
}
.existingJob {
  flex-direction: row;
  height: 70px;
  background-color: #272727;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  flex: 20;
}
.Del {
  background-color: red;
  flex: 1;
}
.fileTypeBtn {
  width: 100%;
  height: 70px;
  background-color: green;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  color: white;
}

.existingJobBtn2 {
  width: 100%;
  height: 40px;
  background-color: green;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  color: white;
}
.btn {
  height: 100px;
  width: 100px;
}
.existingJobBtn {
  width: 100%;
  height: 70px;
  background-color: #f1f1f1;
}
.text {
  color: white;
}
.Edit {
  flex-direction: row;
  height: 100%;
  width: 95%;
  background-color: green;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  flex: 1;
  align-self: flex-end;
  margin-right: 2.5%;
  font-family: "Raleway", sans-serif;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}
