.containerNewJob {
  width: 95vw;
  height: 5vh;
  background-color: #f1f1f1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
  margin-left: 2.5%;
}
.Text {
  color: white;
}
.textInputTest {
  color: white;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}

.textInputTest2 {
  width: 100%;
  color: white;
  font-family: "Inter Tight", sans-serif;
  font-size: 15px;
}
.newJobBtn {
  width: 100%;
  height: 8vh;
  flex: 1;
  margin-top: 5;
  align-items: center;
  margin-bottom: 5;
  flex-direction: row;
  display: flex;
  border-radius: 5px;
}
.temp {
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 98%;
}
.tempText {
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 98%;
  margin-left: 50%;
}
.logintext {
  color: #fff;
  text-align: center;
  justify-content: center;
  flex: 1;
}
.SubBtnNewJob {
  justify-content: center;
  align-content: center;
  background-color: green;
  flex: 5;
  height: 4vh;
  color: white;
  padding-right: 1%;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  cursor: pointer;
  border-radius: 5px;
}
.inputNewJob {
  flex: 1;
  height: 4vh;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
  border-radius: 0px;
  margin-left: 1vw;
  border-radius: 5px;
  margin-right: 1vw;
  border-style: solid;
}
.tempdiv {
  height: 100%;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}
@media screen and (max-width: 768px) {
  .SubBtnNewJob {
    flex: 1;
  }
}
