.All {
  overflow: scroll;
  scroll-snap-type: both mandatory;
  height: 100vh;
}
.jobsCont {
  overflow-y: scroll;
  z-index: 1000;
  scroll-behavior: smooth;
  height: 77vh;
}
.Gloablcontainer {
  background-color: #272727;
  align-items: center;
  justify-content: "flex-end";
  background-color: #f1f1f1;
  width: 100%;
  margin-top: 1%;
}
.JobsTitle {
  text-align: center;
  width: 100%;
  justify-content: center;
  font-size: 3vh;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 35%,
    rgba(42, 42, 42, 1) 100%
  );
  color: white;
  padding: 1%;
  padding-left: 0%;
  padding-right: 0%;
  margin-bottom: 1%;
  font-family: "Inter Tight", sans-serif;
}
.existingJobBtn {
  flex: 20;
  background-color: #272727;
  position: relative;
}
/*.existingJob:hover .borderBottom {
  position: absolute;
  background-color: #ff5700;
  background-color: green;
  width: 20%;
  height: 1px;
  bottom: 0;
  right: 10px;
}
.existingJob:hover .borderRight {
  position: absolute;
  background-color: #ff5700;
  background-color: green;
  width: 1px;
  height: 80%;
  bottom: 10px;
  right: 0%;
}
.existingJob:hover .borderBottom::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-top: 1px solid #272727;
  border-left: 1px solid #ff5700;
  border-left: 1px solid green;
  width: 0;
}
.existingJob:hover .borderRight::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #272727;
  border-right: 1px solid #ff5700;
  border-right: 1px solid green;
  width: 0;
}*/
.existingJobBtnDiv {
  flex: 20;
  background-color: #272727;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  /*border-bottom: 3px;
  border-right: 3px;
  border-style: solid;
  border-left: 0px;
  border-color: #ff7000;
  border-top: 0px;*/
  width: 60%;
  margin-right: 20%;
  margin-left: 20%;

  padding-right: 5%;
  padding-bottom: 1%;
  padding-top: 1%;
}
.menuIcon {
  padding-left: 1%;
  color: white;
  transition: all 800ms ease;
}
.menuIconActive {
  padding-bottom: 1%;
  width: 5%;
  color: white;
  transition: all 800ms ease;
  transform: rotate(90deg);
}
/*.borderBottom {
  position: absolute;
  background-color: #ff5700;
  background-color: #272727;
  width: 20%;
  height: 1px;
  bottom: 0;
  right: 10px;
  transition: all 1000ms ease;
}
.borderRight {
  position: absolute;
  background-color: #ff5700;
  background-color: #272727;
  width: 1px;
  height: 80%;
  bottom: 10px;
  right: 0%;
  transition: all 1000ms ease;
}
.borderBottom:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-top: 1px solid #272727;
  border-left: 1px solid #ff5700;
  border-left: 1px solid #272727;
  width: 0;
  transition: all 1000ms ease;
}
.borderRight:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #272727;
  border-right: 1px solid #ff5700;
  border-right: 1px solid #272727;
  width: 0;
  transition: all 1000ms ease;
}*/
.testTemp {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}
.existingJob {
  display: flex;
  height: 75px;
  width: 100%;
  position: relative;
  display: flex;
}
.EditJobs {
  color: white;
  margin: 10px;
  margin-left: 0;
  margin-right: 0;
  height: 5vh;
  width: 100%;
  background-color: green;
  position: relative;
  font-family: "Inter Tight", sans-serif;
  font-size: 1.5vh;
}
.existingJob2Jobs {
  flex-direction: row;
  height: 100%;
  background-color: red;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: white;
  width: 100%;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.existingJob2JobsDiv {
  flex-direction: row;
  height: 100%;
  background-color: red;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: white;
}
@media screen and (max-width: 768px) {
  .jobsCont {
    height: 68vh;
  }

  .ContainerNavbar:hover .jobsCont {
    height: 10vh;
  }
}
