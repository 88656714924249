.container {
  width: 100%;
  background-color: #f1f1f1;
  justify-content: "flex-end";
  align-items: center;
}
.existingJob {
  width: 95%;
  height: 70px;
  background-color: #272727;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.fileTypeBtn {
  width: 100%;
  height: 70px;
  background-color: green;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  color: white;
}
.btn {
  height: 100px;
  width: 100px;
}
.existingJobBtn {
  width: 100%;
  height: 70px;
  background-color: #f1f1f1;
}
.Text {
  color: white;
}
