.printCont {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-family: "Inter Tight", sans-serif;
  font-size: 3vh;
  z-index: 100;
  margin-left: 40%;
  margin-bottom: 2%;
}
.printBtn {
  width: 20vw;
  padding: 1.25%;
  background-color: green;
  border-radius: 20px;
  color: white;
  border-color: white;
  border-width: 1px;
  border-style: solid;
  font-size: 2.2vh;
}
.printBtn:hover {
  cursor: pointer;
}
.TimesheetGC {
  height: 96vh;
  overflow-y: scroll;
}

.ContainerNavbar:hover .TimesheetGC {
  height: 92vh;
}
.ContainerNavbar:hover .printCont {
  top: 4vh;
}
.globalContainer {
  width: 100%;
  flex: 1;
  position: relative;
}
.header {
  display: flex;
  width: 100%;
  flex: 2.3;
  background-color: #f1f1f1;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.hGridTitles {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.hGridInputs {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 5;
}
.hGridColumns {
  flex: 1;
  margin: 4;
  background-color: #f1f1f1;
}
.bodyHeader {
  width: 100%;
  flex: 0.5;
  background-color: #f1f1f1;
  flex-direction: row;
  display: flex;
}
.bodyScroll {
  width: 100%;
}
.body {
  width: 100%;
  flex: 6;
  background-color: #f1f1f1;
  margin-bottom: 5px;
}
.footerDoc {
  width: 100%;
  flex-direction: column;
  flex: 1;
  background-color: #f1f1f1;
  margin-top: 5;
}
.footerPage {
  flex-direction: row;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 1vh;
}
.Text {
  color: black;
}
.SubBtn {
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: green;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}
.sigDiv {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SubBtnSig {
  width: 100%;
  /* flex: 1 1; */
  background-color: green;
  justify-content: center;
  height: 10vh;
  align-content: center;
  cursor: pointer;
}
.SubBtnTS {
  cursor: pointer;
  width: 100%;
  /* flex: 1 1; */
  background-color: green;
  justify-content: center;
  height: 10vh;
  align-content: center;
}
.SigCap {
  width: 100%;
  flex: 1;
  background-color: green;
  justify-content: center;
  align-content: center;
}
.loginText {
  color: #fff;
  text-align: center;
}
.footerDocTitle {
  padding-left: 10;
  background-color: #f1f1f1;
  width: 100%;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.footerdivTitle {
  flex: 1.5;
  width: 100%;
  border-bottom-width: 2;
  border-color: #ededed;
}
.footerdivContent {
  flex: 4;
  height: 100%;
}
.textInputTest {
  color: black;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  margin: 0%;
  padding: 0%;
  padding-left: 4px;
  resize: vertical;
}
.textInputTestTS {
  width: 100%;
  height: 3vh;
  color: black;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  padding-bottom: 0px;
  padding-top: 0px;
  border-style: none;
}
.textInputTestTSComment {
  width: -webkit-fill-available;
  padding-left: 4px;
  color: black;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
  resize: vertical;
}
.textInputHeader {
  width: 100%;
  height: 3vh;
  color: black;
  text-align: center;
  background-color: #ededed;
  border-width: 0px;
  border-style: solid;
  border-color: #d4d4d4;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  font-size: 2vh;
}
.textInputHeaderHeader {
  width: 100%;
  height: 100%;
  color: black;
  text-align: center;
  background-color: #ededed;
  border-width: 0px;
  border-style: solid;
  border-color: #d4d4d4;
  font-family: "Inter Tight", sans-serif;
  font-size: 2vh;
}
.TextInputOne {
  height: 3vh;
  flex: 1;
  background-color: #f1f1f1;
  padding-left: 0px;
  border-color: #d4d4d4;
  text-align: center;
  margin: 0px;

  border-width: 1px;
  border-style: solid;
}
.TextInputTwo {
  height: 3vh;
  background-color: #f1f1f1;
  align-items: center;
  flex: 1;
  justify-content: center;

  border-color: #d4d4d4;
  border-width: 1px;
  border-style: solid;
}
.TextInputEmpty {
  height: 100%;
  flex: 1;
  background-color: #f1f1f1;
  border-color: #d4d4d4;
  border-width: 1px;
  border-style: solid;
}
.bGridSmall {
  height: 100%;
  flex: 2;
  background-color: #f1f1f1;
  border-color: #d4d4d4;
  border-width: 1px;
  border-style: solid;
  flex-direction: row;
}
.bGridSmallBtn {
  flex: 2;
  background-color: #f1f1f1;
  border-color: #d4d4d4;
  border-width: 1px;
  border-style: solid;
  flex-direction: row;
  padding: 0%;
}
.bGridMedium {
  height: 100%;
  flex: 4;
  border-color: #d4d4d4;
  border-width: 1px;
  border-style: solid;
}
.bGridLarge {
  height: 100%;
  flex: 8;
  background-color: #f1f1f1;
  border-color: #d4d4d4;
  border-width: 1px;
  border-style: solid;
}
.bGridColumns {
  background-color: #ededed;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: center;
}
.bodyHeaderBodyWeb {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  background-color: #f1f1f1;
}
.bodyHeaderBodyMobile {
  display: none;
  width: 0%;
  height: 0%;
  flex-direction: column;
  background-color: #f1f1f1;
}
.prev {
  flex: 1;
  height: 100%;
  width: 100%;
}
.sigBtn {
  width: 50%;
  flex: 1;
  background-color: green;
  justify-content: center;
  align-content: center;
}
.footerPageSig {
  flex: 2;
  display: flex;
  flex-direction: row;
}
.footerPageSigs {
  width: 100%;
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
input {
  padding-bottom: 0px;
  padding-top: 0px;
  border-style: none;
  box-sizing: border-box;
  height: 3vh;
  font-size: 2vh;
}
@media screen and (max-width: 768px) {
  .printCont {
    display: none;
  }
  .bGridSmall:target {
    flex: 4;
  }
  .TimesheetGC {
    height: 82vh;
  }

  .bodyHeaderBodyWeb {
    display: none;
    width: 0%;
    height: 0%;
  }
  .bodyHeaderBodyMobile {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
  }

  .hGridInputs {
    flex: 4;
  }
  .textInputHeaderHeader {
    height: 3vh;
  }
  .textInputTestBody {
    height: 3vh;
    font-family: "Raleway", sans-serif;
    font-size: (2vh+2px);
    padding: 0px 2px;
  }
  .trueTS {
    height: 3vh;
  }
  .textInputTestTSComment {
    color: black;
    font-family: "Raleway", sans-serif;
    font-size: 2vh;
  }
  .printCont {
    top: 0.9vh;
  }
  .printCont {
    margin-left: 35%;
  }
  .printBtn {
    padding: 3%;
    width: 30vw;
  }
}
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}
@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
    break-after: always !important;
    page-break-after: always !important;
    page-break-inside: avoid !important;
  }

  .bodyHeader {
    page-break-inside: avoid;
  }
  .footerPage {
    page-break-inside: avoid;
  }
  .footerDoc {
    page-break-inside: avoid;
  }
  .SubBtnTS {
    display: none;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
